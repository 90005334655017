var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        { staticClass: "qs-profil-v2" },
        [
          _c("qs-layout-notifications"),
          _c("router-view", { staticClass: "qs-profil-v2__view" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }